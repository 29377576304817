<!--智慧军营-->
<template>
  <div id="wisdomBarracks">
    <el-row>
      <el-col :span="24">
        <div class="head_title">
          <div class="title_name">
            <span>
              方案概述
            </span>
            <div></div>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div style="text-align:center;padding:20px;">
          "利用物联网、高新智能网络、云端大数据处理等新技术，推动军营环境数字化、智能化、网络化、高效化、融合化、集约化、可视化管理，推进军营智慧化、现代化建设发展。"
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="head_title">
          <div class="title_name">
            <span>
              成功案例
            </span>
            <div></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="case">
      <div class="back">
        <img src="../../../assets/images/programme/left.png" />
      </div>
      <div class="case_detail">
        <div class="case_detail_img">
          <img src="../../../assets/images/programme/programme.png" />
        </div>
        <div class="case_detail_content">
          <h3>
            军事训练档案管理系统
          </h3>
          <div>
            实现对军事训练档案纸质版和电子版智能化管理和保存、训练数据的分析和汇总，解决军队军事训练档案管理不能互通、互联、查找、保存、管理等痛点和难点。
          </div>
        </div>
      </div>
      <div class="next">
        <img src="../../../assets/images/programme/right.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wisdomBarracks",
  mounted() {},
  data() {
    return {};
  },
  props: {
    isMobile: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.head_title {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  .title_name {
    position: relative;
    span {
      z-index: 3;
    }
    div {
      position: absolute;
      bottom: -3px;
      left: 21%;
      width: 53px;
      height: 6px;
      background-color: #015293;
      z-index: 2;
    }
  }
}
.el-row {
  margin: 20px 0;
  .el-col {
    margin-bottom: 20px;
  }
}
#wisdomBarracks{
  padding: 0 11vw 0 10vw;
}
.case {
  display: flex;
  align-items: center;
  .back {
    width: 80px;
  }
  .case_detail {
    padding:20px;
    flex: 1;
    display: flex;
    .case_detail_img {
      flex: 1;
      padding: 20px;
      img {
        width: 100%;
      }
    }
    .case_detail_content {
      flex: 1;
      padding: 20px;
    }
  }
  .next {
    width: 80px;
  }
}
</style>
