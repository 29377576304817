<!-- 解决方案 -->
<template>
  <div id="programme">
    <isLocation :barBtn="location" :addressLi="address"></isLocation>
    <headTitle
      barBtn="解决方案"
      :isMobile="$store.state.isMobile"
      :isActive="isActive"
      @editType="getPage"
    ></headTitle>
    <div class="item">
      <wisdomBarracks></wisdomBarracks>
    </div>
  </div>
</template>

<script>
import isLocation from "@/components/isLocation/index";
import headTitle from "@/components/headTitle/index";
import wisdomBarracks from "./components/wisdomBarracks";
export default {
  name: "programme",
  components: {
    isLocation,
    headTitle,
    wisdomBarracks
  },
  data() {
    return {
      isActive: "wisdomBarracks",
      location: "解决方案",
      address: "智慧军营",
      pageType: "wisdomBarracks",
    };
  },
  methods: {
    getPage(val) {
      this.isActive = val;
      switch (val) {
        case "wisdomBarracks":
          this.address = "智慧军营";
          break;
        case "militaryTraining":
          this.address = "军事训练";
          break;
        case "armyTheme":
          this.address = "强军主题";
          break;
        case "psychologicalWarfare":
          this.address = "心理作战";
          break;
        case "simulation":
          this.address = "模拟仿真";
          break;
        case "battlefieldRescue":
          this.address = "战场救援";
          break;
        case "militaryBigdata":
          this.address = "军事大数据";
          break;
        case "trainingGuidance":
          this.address = "训练指导";
          break;
        default:
          this.address = "";
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
